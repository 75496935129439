<template>
  <section :class="$style.container">
    <div :class="$style.wrap">
      <div :class="$style.head">
        <h2 :class="$style.title">
          <span :class="$style.t">Меняй за баллы</span>
          <span :class="$style.t">в магазине</span>
        </h2>

        <div :class="$style.note">
          <span :class="$style.t">
            — получи скины, фрибеты и&nbsp;многое другое
          </span>
        </div>
      </div>

      <div :class="$style.cards">
        <items-card
          v-for="card in cards"
          :key="card.id"
          :card="card"
          :class="$style.card"
        />

        <div :class="$style.banner">
          <div :class="$style.image">
            <img
              :src="isMobile ? bannerImgMob : bannerImg"
              alt=""
              loading="lazy"
            />
          </div>

          <common-button
            :class="$style.button"
            :amplitude="[
              'button_click',
              {
                button_name: 'login',
                button_text: 'Начни 2'
              }
            ]"
          >
            <span>Начни</span>
          </common-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import ItemsCard from './card'
import CommonButton from '../common/button'
import bannerImgMob from './images/banner-img-mob.png'
import bannerImg from './images/banner-img.png'
import { useLandingMainShopProductsStore } from '~/stores/landings/main/shop/products'

const { $matchMedia } = useNuxtApp()
const landingMainShopProductsStore = useLandingMainShopProductsStore()
const isMobile = computed(() => $matchMedia.down(768))
const items = computed(() => landingMainShopProductsStore.items)
const cards = computed(() =>
  items.value.map(item => ({
    id: item.id,
    count: item.amount,
    name: item.resource.name,
    img: item.resource.image_url.large,
    price: item.price,
    code: item.coin.code
  }))
)
</script>

<style lang="scss" module>
.container {
  padding: 15em 0 20em 0;
  margin-bottom: -8em;

  @include down(md) {
    padding: 8em 1.6em 12em;
    margin-bottom: -4em;
  }
}

.wrap {
  max-width: 148em;
  margin: auto;
}

.head {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 7em;

  @include down(md) {
    display: block;
    margin-bottom: 0;
  }
}

.title {
  @include down(md) {
    text-align: center;
  }

  .t {
    font-family: var(--primary-font);
    font-style: normal;
    font-size: 6.4em;
    line-height: 120%;
    text-transform: uppercase;
    color: #ffffff;
    display: block;

    @include down(md) {
      font-size: 3.2em;
    }

    &:last-child {
      color: var(--root-color-primary);

      @include down(md) {
        margin-top: 0.125em;
      }
    }
  }
}

.note {
  @include down(md) {
    margin-top: 1.6em;
    text-align: center;
  }

  .t {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 400;
    font-size: 3.2em;
    line-height: 130%;
    color: #00c7b1;

    @include down(md) {
      font-size: 2em;
    }
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5em;

  @include down(md) {
    gap: 0.8em;
    margin: 3em auto 0;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 1.6em;
  }

  @include down(md) {
    margin: 4em auto 0;
    padding: 0;
    gap: 1.5em;
  }

  .card {
    height: 34.2em;
    @include down(md) {
      height: 19.6em;
    }
  }

  .banner {
    grid-column: span 2;
    background-image: url(@/components/landings/season6/items/images/card-banner.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    border-radius: 1.2em;
    position: relative;
    height: 34.2em;

    @include down(md) {
      height: 28.2em;
      border-radius: 0.6em;
      margin-top: 1.5em;
      background-image: url(@/components/landings/season6/items/images/card-banner-mob.jpg);
    }

    .image {
      position: absolute;
      top: -7em;
      right: -10em;
      width: auto;
      height: 44.5em;

      @include down(md) {
        top: -5em;
        right: -3.2em;
        height: 27.5em;
      }

      img {
        width: auto;
        height: 100%;
      }
    }

    .button {
      position: absolute;
      bottom: 0;
      right: 3em;
      transform: translate(0, 50%) skew(-15deg);

      @include down(md) {
        bottom: -1.3em;
        right: 2.3em;
      }
    }
  }
}
</style>
